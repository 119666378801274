import React from "react";
import "./TwoFactorAuth.css";
// import useDigitInput from "react-digit-input";
import Button1 from "../../shared/Buttons/Button1";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import Loader from "../../utilities/Loader";
import { toast } from "react-hot-toast";
import OtpInput from "./OtpInput";
import configData from "../../vars.json";
const baseURL = configData.baseUrl ;


const TwoFactorAuth = ({ baseURL3, baseURsL, baseURL2 }) => {
  const [post, setPost] = useState(null);
  const [sms, setSms] = useState(null);
  const [showSend, setSend] = useState(true);
  const [envelopeid, setenvelope] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const headers = {
    Authorization: "Hola Mundo",
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  function handleChange(event) {
    setenvelope(event.target.value);
  }

  function validate(value) {
    var code = value.toString();
    setIsLoading(true);
    axios
      .post(
        baseURL3,
        { id: query.get("id"), code: code },
        {
          headers: headers,
        }
      )
      .then(async (response) => {
        if (response.data == "Wrong Password") {
          toast.error("Wrong OTP");
          setIsLoading(false);
        } else {
          await fetch(response.data.url).then((resi) => {
            resi.blob().then((blob) => {
              var file = new File([blob], "nombre.pdf", {
                type: "application/pdf",
              });
              getBase64(file)
                .then((result) => {
                  file["base64"] = result;
                  setIsLoading(false);
                  console.log(response);
                  console.log("resultado");
                  console.log("NUEVO codigo");
                  console.log(result);
                  navigate("/message", {
                    state: {
                      url:
                        response.data.mediaType == "Documento"
                          ? result
                          : response.data.url,
                      sender: response.data.sender,
                      sender_last: response.data.sender_last,
                      sended: response.data.sended,
                      title: response.data.title,
                      message: response.data.message,
                      date: response.data.date,
                      photo: response.data.photo,
                      hash: response.data.hash,
                      mediaType: response.data.mediaType,
                      id: query.get("id"),
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          });
        }
      });
  }
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  async function sendSms() {
    var url = "https://s3.amazonaws.com/pastpost.xherstone.com/certificate.pdf";
    if ((url != "") & (envelopeid == "1B00E908747C44D49BBF4D808EF1AC97")) {
      await fetch(url).then((resi) => {
        resi.blob().then((blob) => {
          var file = new File([blob], "nombre.pdf", {
            type: "application/pdf",
          });
          getBase64(file)
            .then((result) => {
              file["base64"] = result;
              console.log("REDIRECCIONANDO");
              navigate("/validation", {
                state: {
                  url: result,
                  mediaType: "Documento",
                },
              });
            })
            .catch((err) => {
              console.log(err);
            });
        });
      });
    } else {
      navigate("/expired");
    }
  }
  function resendSms() {
    axios
      .post(
        baseURL2,
        { id: query.get("id") },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setSms(response.data);
      });
    setSend(false);
    console.log("sms sent");
  }
  React.useEffect(() => {
    axios
      .post(
        baseURL,
        { id: query.get("id") },
        {
          headers: headers,
        }
      )
      .then((response) => {
        console.log(response);
        var myString =
          response.data.toString().substring(0, 6) +
          "xxx" +
          response.data
            .toString()
            .substring(10, response.data.toString().length);
        console.log(myString);
        setPost(myString);
      });
    // sendSms();
  }, []);
  const handleNotMyNumber = () => {
    toast("Característica en desarrollo", {
      icon: "⚙",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="pt-5 px-3 lg:px-0 lg:pt-[102px]">
      <div className="bg-[#F8F8F8] mx-auto lg:mx-0  rounded-xl p-3 sm:p-5 md:pb-10 lg:pl-6 lg:py-9 max-w-[450px] md:max-w-[594px] ">
        <div className="max-w-lg">
          <img
            src="assets/img/authentication.png"
            alt=""
            className="max-w-[50px] md:max-w-[80px]"
          />
          <h1 className="customClass">Verificación de documentos</h1>
          <p className="text-xs md:text-sm font-medium text-headingBlack ">
            Por favor ingresa el envelope ID de tu documento para validar su
            veracidad y da click en verificar.
          </p>
          <div className=" control w-full pt-3 pb-2 sm:pb-3 md:pt-8 md:mb-8">
            <form>
              <input
                className="inputenv"
                type="text"
                value={envelopeid}
                onChange={handleChange}
              />
            </form>
            {/*<OtpInput onChange={validate} /> */}
          </div>
          <div className="  flex lg:justify-end items-center  lg:pr-0 pb-5 border-b mb-3 lg:mb-6">
            {showSend && <Button1 handleclick={sendSms}>Verificar</Button1>}
            {!showSend && <Button1 handleclick={sendSms}>Reenviar</Button1>}
            {/* <button className="text-sm font-medium text-[#84818A]">Back</button> */}
            <button
              className="text-xs lg:text-sm font-semibold text-[#5542F6]"
              onClick={sendSms}
            >
              {/*}  ¡No recibí el código! */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
