import "./main.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import playicons from "./playicon.svg";
import pauseicon from "./pause.svg";
import fullscreenicon from "./fullscreenIcon.svg";
import Loader from "../../utilities/Loader";
import configData from "../../vars.json";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { updateSampleSection } from "../sample-base";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { MyContext } from "../../utilities/ContextData";


const Main = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { main, setMain, setMyHome } = useContext(MyContext);
  const videoRef = useRef(null);
  useEffect(() => {
    

    
  });
  useEffect(() => {
    setMyHome(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
   
 
    
  }, []);



  const handlePlay = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };
  const handleFullScreen = () => {
    videoRef.current.requestFullscreen();
  };
  React.useEffect(() => {
    updateSampleSection();
  }, []);
  const [numPages, setNumPages] = React.useState(null);
  const headers = {
    Authorization: "Hola Mundo",
  };

  // const [show, setShow] = React.useState(false)-no-;
  // const [mediaType, setmediaType] = React.useState(location.state.mediaType)-no-;

  const location = useLocation();


  function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }

  var fileshow = "";
  console.log("LLEGO AQUI")
  switch (location.state.mediaType) {

    case "Img":
      var sectionStyle = {
        backgroundImage: "url(" + location.state.url + ")",
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };

      break;
    case "Video":
      fileshow = (
        <>
          {" "}
          <video
            ref={videoRef}
            onEnded={handlePause}
            className="left-2/4 -translate-x-2/4 w-full h-full absolute top-0 z-[1] lg:object-cover"
          >
            <source src={location.state.url} type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
          </video>
          <img
            src={isPlaying ? pauseicon : playicons}
            alt="Play Icon"
            onClick={handlePlay}
            className={`absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 cursor-pointer z-50 max-w-[60px] lg:max-w-[96px] ${
              isPlaying ? "opacity-0 group-hover:opacity-100" : "opacity-100"
            }`}
          />
          <img
            src={fullscreenicon}
            alt="Play Icon"
            onClick={handleFullScreen}
            className={`absolute top-5 right-5 lg:hidden cursor-pointer hover:scale-105 transition-transform z-50 w-5 ${
              isPlaying ? "opacity-0 group-hover:opacity-100" : "opacity-0"
            }`}
            title="Fullscreen"
          />
        </>
      );
      break;
    case "Documento":
      console.log("Es un PDF nuevo")
      fileshow = (
        <div className="pdfCont">
          <PdfViewerComponent
            id="container"
            documentPath={location.state.url}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={{ height: "80vh" }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                Annotation,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        </div>
      );

      break;
    case "DocumentoWrite":
      fileshow = (
        <div className="pdfCont">
          <PdfViewerComponent
            id="container"
            documentPath={location.state.url}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={{ height: "80vh" }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                Annotation,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        </div>
      );

      break;
    case "Audio":
      fileshow = (
        <div className="audioP">
          <img
            className="max-w-[100px] mx-auto"
            alt="audio"
            src="/assets/img/volume2.png"
          />
          <ReactAudioPlayer
            src={location.state.url}
            autoPlay
            controls
            className=" mx-auto"
          />
        </div>
      );
      break;
  }



  

  //reloading problem solved here......................................
  const navigate = useNavigate();
  /*
  useEffect(() => {
    const handleNavigationTiming = (navigationTiming) => {
      // Check if the page was navigated via a reload or refresh
      if (
        navigationTiming.type === "reload" ||
        navigationTiming.type === "back_forward"
      ) {
        // Navigate to the expired page
        setMain(false);
      //  navigate("/expired");
        // Push a new state onto the history stack to prevent the user from navigating back
      }
    };

    // Check if the Navigation Timing API is supported
    if (window.PerformanceNavigationTiming) {
      // Get the PerformanceNavigationTiming object
      const navigationTiming = performance.getEntriesByType("navigation")[0];

      // Call the handleNavigationTiming function with the PerformanceNavigationTiming object
      handleNavigationTiming(navigationTiming);
    }
  }, [navigate]);
  */

  if (isLoading) {
    return <Loader />;
  }
  return (
    <section className="grid grid-cols-4 bg-white">

      <div className="min-h-[35vh] lg:min-h-screen relative group col-span-12  lg:col-span-12 xl:col-span-12 bg-gray-100">
        {/* showing files contents video/image  */}
        <div id="background" style={sectionStyle}></div>
        {fileshow}
     
      </div>
 
    </section>
  );
};

export default Main;
