import React, { useContext, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import TwoFactorAuth from "../../components/TwoFactorAuth/TwoFactorAuth";

import { useNavigate } from "react-router-dom";
import { MyContext } from "../../utilities/ContextData";

const Home = () => {
  const [url, setUrl] = React.useState(null);
  const [value, onChange] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [showMo, setShowMo] = React.useState(false);
  const [sender, setSender] = React.useState("");
  const [sender_last, setSender_last] = React.useState("");
  const [sended, setSended] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [date, setDate] = React.useState("");
  const [showSend, setSend] = React.useState(true);

  //send back user to main page
  const { myHome, setMyHome } = useContext(MyContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!myHome) {
      navigate("/");
    }
  }, [myHome, navigate]);

  useEffect(() => {
    const handleNavigationTiming = (navigationTiming) => {
      // Check if the page was navigated via a reload or refresh
      if (navigationTiming.type === "back_forward") {
        // Navigate to the message page
        navigate("/expired");
      }
    };

    // Check if the Navigation Timing API is supported
    if (window.PerformanceNavigationTiming) {
      // Get the PerformanceNavigationTiming object
      const navigationTiming = performance.getEntriesByType("navigation")[0];

      // Call the handleNavigationTiming function with the PerformanceNavigationTiming object
      handleNavigationTiming(navigationTiming);
    }
  }, [navigate]);
  return (
    <section className="grid grid-cols-4 bg-white relative">
      <div
        className="min-h-screen bg-cover hidden lg:block lg:col-span-1 2xl:col-span-2"
        style={{
          backgroundImage: `url(assets/img/pastpostHome.webp)`,
        }}
      ></div>
      <div className="pt-3 lg:pl-28 lg:pt-6  lg:pr-20 col-span-4 lg:col-span-3 2xl:col-span-2">
        <Navbar id="" />
        <TwoFactorAuth
          baseURL={""}
          baseURL2={""}
          baseURL3={""}
        />
      </div>
    </section>
  );
};

export default Home;
